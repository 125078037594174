import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import EnvInject from '@/utils/env-inject'
import { lsSet } from '@/utils/util'
import store from './index'
import Vue from 'vue'
import { common } from '../http/common'
import { LOCAL_STORAGE_ITEM, LOGIN_PAGE_ROUTE_PATH } from '@/utils/constant'
interface TokenItem {
  token: string
  expire: number
}

interface UserInfo {
  accountId: string
  accountLevel: number
  accountName: string
  accountType: number
  userLevel: number
  username: string
}

export enum SiteType {
  ADMIN = 'admin',
  AGENT = 'agent',
  CLIENT = 'client'
}

interface AppConfig {
  siteType?: SiteType //站点类型
  lang?: string //语言
  theme?: string //主题
}
interface MenuNavi {
  menus: Array<string>
  permissions: Array<string>
}
let siteType = SiteType.CLIENT
if (process.env.VUE_APP_THEME === 'vnvtt') {
  if (window.location.href.indexOf('admin') > 0) {
    siteType = SiteType.ADMIN
  }
  if (window.location.href.indexOf('client') > 0) {
    siteType = SiteType.CLIENT
  }
  if (window.location.href.indexOf('agent') > 0) {
    siteType = SiteType.AGENT
  }
}
const DEFAULT_APP: AppConfig = {
  siteType: siteType,
  lang: 'en-GB',
  theme: process.env.VUE_APP_THEME
}

function clearAccountInfo() {
  // //需要把帐号信息清除掉
  // Vue.ls.remove(LOCAL_STORAGE_ITEM.LS_USER_INFO)
  // //需要把菜单权限清除掉
  // Vue.ls.remove(LOCAL_STORAGE_ITEM.LS_SEC_MENU_LIST)
  // Vue.ls.remove(LOCAL_STORAGE_ITEM.LS_SEC_PERMISSION_LIST)
  // Vue.ls.remove(LOCAL_STORAGE_ITEM.LS_SEC_TOKEN)
  sessionStorage.removeItem('root__' + LOCAL_STORAGE_ITEM.LS_USER_INFO)
  sessionStorage.removeItem('root__' + LOCAL_STORAGE_ITEM.LS_SEC_MENU_LIST)
  sessionStorage.removeItem(
    'root__' + LOCAL_STORAGE_ITEM.LS_SEC_PERMISSION_LIST
  )
  sessionStorage.removeItem('smsTpl')
  sessionStorage.removeItem('isTokenSec')

  sessionStorage.removeItem('root__' + LOCAL_STORAGE_ITEM.LS_SEC_TOKEN)
}

@Module({
  name: 'site', //module名称，开启命名空间后会以name为命名空间
  dynamic: true, //是否使用动态加载，简而言之只有在用到当前的module才会加载
  namespaced: true, //是否开启命名空间
  store //挂载的store目标
})
export default class SiteState extends VuexModule {
  app: AppConfig | null = null
  captcha: string | null = null
  userInfo: UserInfo | null = null
  timeZone: number | null = null
  //允许访问的菜单
  secAllowMenu: Array<string> | null = null
  //允许访问的权限
  secAllowPermission: Array<string> | null = null
  //token
  secToken: TokenItem | null = null

  /**
   * 是否展示切换站点
   */
  get allowSwitchSite() {
    return EnvInject.SITE === undefined
  }

  get site(): SiteType | null {
    if (!this.allowSwitchSite) {
      return EnvInject.SITE
    } else {
      return this.app?.siteType || null
    }
  }

  /**
   * 获取服务器的时区，想对于0时区的毫秒数
   */
  get serverTimeZone(): number | null {
    return this.timeZone
  }

  get theme(): string | null {
    return this.app?.theme || null
  }

  get access_token(): string | undefined {
    return this.secToken?.token
  }

  get lang(): string {
    return this.app?.lang || 'en-GB'
  }

  get isValidAccount(): boolean {
    return !!this.access_token && !!this.userInfo
  }

  @Mutation
  updateUserInfo(userInfo: UserInfo) {
    this.userInfo = userInfo

    lsSet(LOCAL_STORAGE_ITEM.LS_USER_INFO, userInfo)
  }

  @Mutation
  updateUserInfoFromLS(userInfo: UserInfo) {
    this.userInfo = userInfo
  }

  @Mutation
  updateTokenInfo(token: TokenItem) {
    this.secToken = token
    lsSet(LOCAL_STORAGE_ITEM.LS_SEC_TOKEN, token)
  }

  @Mutation
  updateTokenInfoFromLS(token: TokenItem) {
    this.secToken = token
  }

  @Mutation
  updateServerTimeZone(millisecond: number) {
    this.timeZone = millisecond
  }

  @Mutation
  updateSite(site: SiteType | null) {
    //切换站点类型，需要登出
    if (this.app) {
      this.app.siteType = site ? site : undefined
      lsSet(LOCAL_STORAGE_ITEM.LS_APP_CONFIG, this.app)
    }
  }

  @Mutation
  updateAppFromLS(app: AppConfig) {
    this.app = app
  }

  @Mutation
  updateAppByDefault() {
    this.app = DEFAULT_APP
    lsSet(LOCAL_STORAGE_ITEM.LS_APP_CONFIG, this.app)
  }

  @Mutation
  updateLang(lang: string | null) {
    if (lang === null) {
      lang = 'en-GB'
    }

    if (this.app) {
      this.app.lang = lang
      lsSet(LOCAL_STORAGE_ITEM.LS_APP_CONFIG, this.app)
    }
  }

  @Mutation
  updateTheme(themeName: string) {
    if (this.app) {
      this.app.theme = themeName
      lsSet(LOCAL_STORAGE_ITEM.LS_APP_CONFIG, this.app)
    }
  }

  @Mutation
  updateSecMenuPerm(data: MenuNavi) {
    this.secAllowMenu = data.menus
    this.secAllowPermission = data.permissions
    lsSet(LOCAL_STORAGE_ITEM.LS_SEC_MENU_LIST, data.menus)
    lsSet(LOCAL_STORAGE_ITEM.LS_SEC_PERMISSION_LIST, data.permissions)
  }

  @Mutation
  updateSecMenuPermFromLS(data: MenuNavi) {
    this.secAllowMenu = data.menus
    this.secAllowPermission = data.permissions
  }

  @Mutation
  regenerateVCode() {
    this.captcha = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      (c) => {
        const r = (Math.random() * 16) | 0
        const v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }
    )
  }

  @Mutation
  logout() {
    clearAccountInfo()
    // sessionStorage.clear()
    location.href = LOGIN_PAGE_ROUTE_PATH
  }
}

export const SiteStateModule = getModule(SiteState)
