/**
 * 基座的信箱
 */
import { SiteStateModule } from '@/store/site_state'

import { MicroMailBox, MicroMessage, MicroMessageState } from '../MicroTypes'
import { LOGIN_PAGE_ROUTE_PATH } from '@/utils/constant'
import { eventBus } from '@/event'
import {
  EVENT_GLOBAL_TOAST,
  ToastConfig,
  ToastConfigLevel
} from '@/event/event_types'

class BaseMailbox implements MicroMailBox {
  onHandle(selector: string, message: MicroMessage): MicroMessage {
    console.log('BaseMailbox onHandle', selector, message)

    if (message.type === 'LOAD%USERINFO') {
      message.data = SiteStateModule.userInfo
    } else if (message.type === 'LOAD%TOKEN') {
      message.data = SiteStateModule.secToken
    } else if (message.type === 'LOAD%SITETYPE') {
      message.data = SiteStateModule.site
    } else if (message.type === 'LOAD%LANG') {
      message.data = SiteStateModule.lang
    } else if (message.type === 'LOAD%THEME') {
      message.data = process.env.VUE_APP_THEME
    } else if (message.type === 'REPORT%INVALID_TOKEN') {
      // eventBus.$emit(EVENT_GLOBAL_TOAST, {
      //   level: ToastConfigLevel.ERROR,
      //   msg: 'Login information has expired'
      // } as ToastConfig)
      SiteStateModule.logout()
      location.href = LOGIN_PAGE_ROUTE_PATH
      message.data = true
    } else if (message.type === 'REPORT%ACCESS_FORBIDDEN') {
      console.log('base should jump to 403 page')
      message.data = true
    }

    message.state = MicroMessageState.READ
    return message
  }

  onReceive(message: MicroMessage): void {
    console.log('BaseMailbox onReceive', message)
  }
}

const baseMailBox = new BaseMailbox()

export default baseMailBox
