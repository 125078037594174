








import antLocale from '@/i18n/antdv_locales'
import { microManager } from '@/micro'
import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  EVENT_GLOBAL_GOTO_PAGE,
  EVENT_GLOBAL_TOAST,
  PAGE_LOGIN,
  PAGE_MAIN,
  ToastConfig,
  ToastConfigLevel
} from '@/event/event_types'
import { LOCAL_STORAGE_ITEM, LOGIN_PAGE_ROUTE_NAME } from '@/utils/constant'
import { SiteStateModule } from '@/store/site_state'
import { NaviStateModule } from '@/store/navi_state'
import { lsGet } from '@/utils/util'
@Component({
  name: 'App'
})
export default class App extends Vue {
  showGlobalToast(config: ToastConfig) {
    if (config.level === undefined) {
      config.level = ToastConfigLevel.INFO
    }

    let info = this.$t(config.msg)
    if (config.args !== undefined) {
      if (Array.isArray(config.args)) {
        info = this.$t(config.msg, config.args)
      } else {
        //是单个字符串
        info = this.$t(config.msg, [config.args])
      }
    }

    //处理参数与多语言
    this.$message.open({
      type: config.level,
      content: info as string
    })
  }

  /**
   * 路由导航
   * @param args
   */
  gotoPage(...args: [any]) {
    console.log('gotoPage', args)
    if (args && args[0] === PAGE_LOGIN) {
      this.$router.push({ name: LOGIN_PAGE_ROUTE_NAME })
    } else if (args && args[0] === PAGE_MAIN) {
      this.$router.push({ path: '/' })
    } else if (args && typeof args[0] === 'object') {
      let pathArg: PathArgument = args[0]
      if (
        NaviStateModule.currentRouteItem &&
        ((pathArg.path &&
          NaviStateModule.currentRouteItem.resourceUrl === pathArg.path) ||
          (pathArg.name &&
            NaviStateModule.currentRouteItem.name === pathArg.name))
      ) {
        //同样的路径重复点击,不需要重复跳转了
        return
      } else {
        if (pathArg.path) {
          this.$router.push({ path: pathArg.path })
        } else if (pathArg.name) {
          this.$router.push({ name: pathArg.name })
        }
      }
    }
  }

  mounted() {
    let url = window.location.href.split('//')[0] + '//' + window.location.host
    let icon = ''
    console.log(process.env.VUE_APP_THEME)
    if (process.env.VUE_APP_THEME === 'vnmbf') {
      icon = url + '/vnmbf.ico'
    } else if (process.env.VUE_APP_THEME === 'vnvtt') {
      icon = url + '/vtt.ico'
    } else {
      icon = url + '/tablink.ico'
    }
    let link = document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = icon
    document.getElementsByTagName('head')[0].appendChild(link)
    this.$eventBus.$on(EVENT_GLOBAL_TOAST, this.showGlobalToast)
    this.$on('hook:beforeDestroy', () =>
      this.$eventBus.$off(EVENT_GLOBAL_TOAST)
    )

    this.$eventBus.$on(EVENT_GLOBAL_GOTO_PAGE, this.gotoPage)
    this.$on('hook:beforeDestroy', () =>
      this.$eventBus.$off(EVENT_GLOBAL_GOTO_PAGE)
    )
  }

  beforeMount() {
    console.log('App beforeMount')

    //微服务预加载
    microManager.prepare()
    //探测userinfo是否加载，如果没加载，则去ls中获取

    if (SiteStateModule.access_token === undefined) {
      const token = lsGet(LOCAL_STORAGE_ITEM.LS_SEC_TOKEN)
      if (token !== null) {
        SiteStateModule.updateTokenInfoFromLS(token)
      }
    }
    if (SiteStateModule.userInfo === null) {
      const userStr = lsGet(LOCAL_STORAGE_ITEM.LS_USER_INFO)
      if (userStr !== null) {
        SiteStateModule.updateUserInfoFromLS(userStr)
      }
    }
    //探测app是否加载，如果没有加载，则去ls中获取
    if (SiteStateModule.app === null) {
      const appTemp = lsGet(LOCAL_STORAGE_ITEM.LS_APP_CONFIG)
      if (appTemp !== null) {
        if (process.env.VUE_APP_THEME === 'vnvtt') {
          if (window.location.href.indexOf('admin') > 0) {
            appTemp.siteType = 'admin'
          }
          if (window.location.href.indexOf('client') > 0) {
            appTemp.siteType = 'client'
          }
          if (window.location.href.indexOf('agent') > 0) {
            appTemp.siteType = 'agent'
          }
        }
        SiteStateModule.updateAppFromLS(appTemp)
      } else {
        SiteStateModule.updateAppByDefault()
      }
    }

    //探测menu是否加载，如果没有加载，则去ls中获取
    if (
      SiteStateModule.secAllowMenu === null ||
      SiteStateModule.secAllowPermission === null
    ) {
      const menuNavTemp = lsGet(LOCAL_STORAGE_ITEM.LS_SEC_MENU_LIST)
      const permNavTemp = lsGet(LOCAL_STORAGE_ITEM.LS_SEC_PERMISSION_LIST)
      if (menuNavTemp && permNavTemp) {
        SiteStateModule.updateSecMenuPermFromLS({
          menus: menuNavTemp,
          permissions: permNavTemp
        })
      }
    }
  }

  @Watch('lang')
  onLangChanged(val: string) {
    console.log('onLangChanged', val)
    document.getElementsByTagName('html')[0].lang = val
    this.$i18n.locale = val
    microManager.broadcastDataChanged('lang', val)
  }

  get userInfo() {
    return SiteStateModule.userInfo
  }

  get lang(): string {
    return SiteStateModule.lang
  }

  get locale() {
    return antLocale[SiteStateModule.lang]
  }
}
