import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from 'vuex-module-decorators'
import store from '@/store/index'
import { eventBus } from '@/event'
import { EVENT_GLOBAL_GOTO_PAGE } from '@/event/event_types'
import { SiteStateModule } from '@/store/site_state'

interface NaviStateItem {
  appName: string
  naviInfos: Array<NaviNode>
}

/**
 * 比较两个url是否相同
 * @param a
 * @param b
 */
function isSameUrl(a?: string, b?: string) {
  if (!!a && a.length > 0 && a.charAt(a.length - 1) === '/') {
    a = a.substring(0, a.length - 1)
  }

  if (!!b && b.length > 0 && b.charAt(b.length - 1) === '/') {
    b = b.substring(0, b.length - 1)
  }

  return a === b
}

function findNav(
  pathName: string,
  navi: Array<NaviNode>
): NaviNode | undefined {
  for (let i = 0; i < navi.length; i++) {
    if (isSameUrl(navi[i].resourceUrl, pathName)) {
      return navi[i]
    }

    const children = navi[i].children
    let ret
    if (children) {
      ret = findNav(pathName, children)
      if (ret) {
        return ret
      }
    }
  }
}

@Module({
  name: 'navi', //module名称，开启命名空间后会以name为命名空间
  dynamic: true, //是否使用动态加载，简而言之只有在用到当前的module才会加载
  namespaced: true, //是否开启命名空间
  store //挂载的store目标
})
export default class NaviState extends VuexModule {
  private naviInfoRecord: Record<string, Array<NaviNode>> = {}
  private _menuCollapsed = false
  currentRouteItem: NaviNode | null = null

  @Mutation
  addNaviInfo(info: NaviStateItem) {
    console.log('addNaviInfo', info.appName, info.naviInfos)

    const appNaviList = this.naviInfoRecord[info.appName]
    if (appNaviList === undefined) {
      this.naviInfoRecord[info.appName] = info.naviInfos
    } else {
      this.naviInfoRecord[info.appName] = this.naviInfoRecord[
        info.appName
      ].concat(info.naviInfos)
    }
  }

  @Mutation
  clearNaviInfo(appName: string) {
    const appNaviList = this.naviInfoRecord[appName]
    if (appNaviList !== undefined) {
      delete this.naviInfoRecord[appName]
    }
  }

  @Mutation
  toggleMenuCollapseState() {
    this._menuCollapsed = !this._menuCollapsed
  }

  /**
   * 激活的主菜单代号
   */
  get activeMainMenuName(): string | null {
    if (this.currentRouteItem) {
      let nowItem = this.currentRouteItem
      while (!!nowItem.parent) {
        nowItem = nowItem.parent
      }
      return nowItem.name
    }
    return null
  }

  /**
   * 侧栏菜单是否是折叠状态
   */
  get menuCollapsed(): boolean | string {
    if (this.activeMainMenuName === 'client.biz.code.index') {
      return 'index'
    } else {
      return this._menuCollapsed
    }
  }

  /**
   * 跳转后，根据路由来解析对象
   * @param routeItem
   */
  @Mutation
  updateCurrentRouteItem(routeItem: NaviNode | undefined) {
    console.log('updateCurrentRouteItem', routeItem)
    if (!!routeItem) {
      this.currentRouteItem = routeItem
    }
  }

  @Action({ commit: 'updateCurrentRouteItem' })
  updateCurrentRoutePath(pathName: string) {
    const item = findNav(pathName, this.navi)
    console.debug('NaviState updateCurrentRoutePath', item)

    if (item) {
      //菜单中且有子菜单，跳到叶子菜单中
      let tempItem: NaviNode = item
      while (!!tempItem.children && tempItem.children.length > 0) {
        tempItem = tempItem.children[0]
      }

      if (tempItem && item !== tempItem) {
        eventBus.$emit(EVENT_GLOBAL_GOTO_PAGE, {
          path: tempItem.resourceUrl
        })
        return tempItem
      }
    } else {
      //菜单中没有该路径, 跳到默认菜单
      eventBus.$emit(EVENT_GLOBAL_GOTO_PAGE, {
        path: this.defaultOpenMenu.resourceUrl
      })
    }

    return item
  }

  get navi(): Array<NaviNode> {
    const records: any[] = []
    for (const appName in this.naviInfoRecord) {
      const appList = this.naviInfoRecord[appName]

      //1. 补充父节点
      function assignParent(list: Array<NaviNode>) {
        list.forEach((r: NaviNode) => {
          r.children?.forEach((c) => {
            c.parent = r
          })

          if (r.children !== undefined) {
            assignParent(r.children)
          }
        })
      }

      assignParent(appList)

      //2.计算 resourceUrl
      function getResourceUrl(path: string, node: NaviNode): string {
        if (node.parent !== undefined) {
          return getResourceUrl(node.parent.path + '/' + path, node.parent)
        }
        return path
      }

      function calcResourceUrl(list: Array<NaviNode>) {
        list.forEach((r: NaviNode) => {
          r.resourceUrl = (
            '/' +
            (appName === 'base' ? '' : appName) +
            '/' +
            getResourceUrl(r.path, r)
          ).replace('//', '/')
          if (r.children !== undefined) {
            calcResourceUrl(r.children)
          }
        })
      }

      calcResourceUrl(appList)
      appList.forEach((app) => {
        records.push(app)
      })
    }

    //3.执行权限过滤, 在登录的时候加载的allowMenu拿出来过滤一遍
    console.debug('KKK Records ', records)
    function filterByAllowMenu(tree: NaviNode[]) {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].children && tree[i].children!.length > 0) {
          //如果不是叶子结点,继续遍历
          filterByAllowMenu(tree[i].children ?? [])
        } else {
          //是叶子节点，查看是否在允许菜单里面
          if (
            SiteStateModule.secAllowMenu &&
            !tree[i].important &&
            SiteStateModule.secAllowMenu.indexOf(tree[i].name) === -1
          ) {
            tree.splice(i--, 1)
          }
        }
      }
    }

    //3.1 先把叶子节点拿出来过滤一遍
    filterByAllowMenu(records)

    function removeNoChildrenMenuNode(tree: NaviNode[]) {
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].children) {
          //存在该属性, 注意!! 叶子节点，或者说具体页面不允许带有children属性，不能声明为空数组[]
          if (tree[i].children!.length > 0) {
            //有内容
            removeNoChildrenMenuNode(tree[i].children ?? [])
          } else {
            //无内容
            tree.splice(i--, 1)
          }
        }
      }
    }

    //3.2 然后去掉没有子菜单的节点
    removeNoChildrenMenuNode(records)
    console.debug('KKK Records 2222', records)

    // 3.2过滤有问题，i为0时，无法删掉children为0的数据,在对处理后的数据过滤一次
    records.forEach((items, index) => {
      if (items.children && items.children.length === 0) {
        records.splice(index, 1)
      }
    })

    //4. 排下序
    for (let i = 0; i < records.length; i++) {
      if (records[i] !== null && records[i].name === 'client.biz.code.index') {
        const temp = records[i]
        records.splice(i, 1)
        records.unshift(temp)
        break
      }
    }

    return records
  }

  get sliderMenus(): Array<NaviNode> {
    const naviList: Array<NaviNode> = this.navi

    let result: Array<NaviNode> | undefined
    naviList.some((n) => {
      if (n.name === this.activeMainMenuName) {
        result = n.children
      }
    })

    return result ?? []
  }

  get defaultOpenMenu(): NaviNode {
    let selectedNode = this.navi[0]
    while (selectedNode.children && selectedNode.children.length > 0) {
      selectedNode = selectedNode.children[0]
    }
    return selectedNode
  }

  /**
   * 是否侧边栏菜单伸缩按钮
   */
  get showCollapsedBtn(): boolean {
    return this.activeMainMenuName !== 'client.biz.code.index'
  }
}

export const NaviStateModule = getModule(NaviState)
