import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import {
  addGlobalUncaughtErrorHandler,
  initGlobalState,
  MicroAppStateActions,
  prefetchApps,
  registerMicroApps,
  start
} from 'qiankun'
import { message } from 'ant-design-vue'
import { FrameworkConfiguration } from 'qiankun/es/interfaces'
import baseController from '@/micro/message/BaseController'
import baseMailBox from '@/micro/message/BaseMailbox'
import { NaviStateModule } from '@/store/navi_state'

import {
  MicroAppConfig,
  MicroMessageState,
  MicroState
} from '@/micro/MicroTypes'

const rawApps = [
  {
    name: 'icop-main',
    entry:
      process.env.NODE_ENV === 'production'
        ? `${location.origin}/micro-main/`
        : 'http://localhost:7600/icop-main/'
  }
]

class MicroManager {
  private _apps?: Array<MicroAppConfig>
  private _appMainfest: Record<string, any> = {}
  private static APPNAME = 'BASE'

  //通用的属性
  private _commonAppProp = {
    props: {
      baseWindow: window,
      controller: baseController
    }
  }

  //初始化状态
  private _initialState: MicroState = {
    BUSY: false,
    MODAL: false
  }

  private _prefetch = true

  constructor() {
    NProgress.configure({ showSpinner: false })
    this.loadMicroAppConfig()
    //把基座自己的信箱挂上去
    baseController.registerMailBox(MicroManager.APPNAME, baseMailBox)
  }

  private fetchManifest(appEntry: string, appName: string) {
    //记载对应的微应用清单文件
    fetch(appEntry + 'manifest.json')
      .then((response) => response.json())
      .then((result) => {
        this._appMainfest[appName] = result
        console.log('menu app', appEntry, appName, result, result.routes)
        NaviStateModule.addNaviInfo({
          appName: appName,
          naviInfos: result.routes
        })
      })
  }

  /**
   * 加载所有的微应用配置
   * @private
   */
  private loadMicroAppConfig() {
    //加载所有有的
    this._apps = []
    for (const a of rawApps) {
      //组装对应的微应用配置
      this._apps?.push(
        Object.assign({}, a, this._commonAppProp, {
          container: '#microContainer',
          activeRule: a.name
        })
      )

      this.fetchManifest(a.entry, a.name)
    }
    // base 在其他页刷新页面 文件路径获取异常，导致home页加载失败
    const baseEntry =
      process.env.NODE_ENV === 'production'
        ? `${location.origin}/`
        : 'http://localhost:7739/'
    this.fetchManifest(baseEntry, 'base')
  }

  static errorHandler(event: Event | string) {
    const { message: msg } = event as any
    // 加载失败时提示
    if (msg && msg.includes('died in status LOADING_SOURCE_CODE')) {
      // message.error(
      //   'The sub-application failed to load. Please check whether the application is runnable.'
      // )
    }
  }

  /**
   * 预配置
   */
  prepare() {
    if (this._apps === undefined) return
    if (this._prefetch) prefetchApps(this._apps)

    //做错误管理
    addGlobalUncaughtErrorHandler(MicroManager.errorHandler)

    //注册微应用
    registerMicroApps(this._apps, {
      beforeLoad: this.beforeLoad,
      afterMount: this.afterMount
    })

    //初始化状态机制
    const actions: MicroAppStateActions = initGlobalState(this._initialState)
    actions.onGlobalStateChange(this.globalStateChangeHandler)
  }

  /**
   * 报告全局信息有该表
   * @param typ
   * @param message
   */
  broadcastDataChanged(typ: string, message: any) {
    console.log('MicroManager broadcastDataChanged', typ, message)
    baseController.broadcast({
      type: typ,
      state: MicroMessageState.UNREAD,
      data: message
    })
  }

  private globalStateChangeHandler(
    state: Record<string, any>,
    prevState: Record<string, any>
  ): void {
    //监听公共状态的变化
    console.log('子传父', prevState, state)
  }

  /**
   * 启动微服务
   * @param config
   */
  start(config?: FrameworkConfiguration) {
    start(config)
  }

  /**
   * qiankun 生命周期钩子 - 加载前
   * @param app
   */
  public beforeLoad(app: any) {
    // 加载子应用前，加载进度条
    NProgress.start()
    console.log('before load', app, app.name)
    return Promise.resolve()
  }

  /**
   * qiankun 生命周期钩子 - 挂载后
   * @param app
   */
  public afterMount(app: any) {
    // 加载子应用前，进度条加载完成
    NProgress.done()

    // 让vue devtools 支持子应用
    if (
      window.__QIANKUN_MICRO_APP_VM__ &&
      process.env.NODE_ENV === 'development'
    ) {
      // vm.$children.push(window.__QIANKUN_MICRO_APP_VM__)
    }

    console.log('after mount', app, app.name)
    return Promise.resolve()
  }
}

const microManager = new MicroManager()

export { microManager }
