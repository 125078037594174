import Vue from 'vue'
import { message } from 'ant-design-vue'
import { common, randomUUID } from '../http/common'
import { I18n as i18n, I18NPlugin } from '@/i18n'
export const LS_EXPIRE_TIME = 7 * 24 * 60 * 60 * 1000
import { SiteStateModule } from '@/store/site_state'
import { LOCAL_STORAGE_ITEM, LOGIN_PAGE_ROUTE_PATH } from '@/utils/constant'
/**
 * 设置本地存储
 * 需要注意ls插件的全局前缀配置，保证设置数据符合预期
 * @param name - 存储名称
 * @param value - 值
 */
export const lsSet = (name: any, value: any) => {
  if (
    name === LOCAL_STORAGE_ITEM.LS_SEC_TOKEN ||
    name === LOCAL_STORAGE_ITEM.LS_APP_CONFIG
  ) {
    sessionStorage.setItem('root__' + name, common.extStringify(value))
  } else {
    sessionStorage.setItem(
      'root__' + name,
      common.extStringify(value, SiteStateModule.access_token)
    )
  }
  // Vue.ls.set(name, common.extStringify(value), LS_EXPIRE_TIME)
}

/**
 * 读取本地存储
 * 需要注意ls插件的全局前缀配置，保证读取数据符合预期
 * @param name - 名称
 */
export const lsGet = (name: any) => {
  // return common.extParse(Vue.ls.get(name))
  if (sessionStorage.getItem('root__' + name)) {
    if (
      name === LOCAL_STORAGE_ITEM.LS_SEC_TOKEN ||
      name === LOCAL_STORAGE_ITEM.LS_APP_CONFIG
    ) {
      return common.extParse(sessionStorage.getItem('root__' + name))
    } else {
      return common.extParse(
        sessionStorage.getItem('root__' + name),
        SiteStateModule.access_token
      )
    }
  }
  return null
}

/**
 * json 转 formdata
 * @param json
 */
export const jsonToFormdata = (json: any) => {
  const fd = new FormData()
  for (const key in json) {
    if (json[key] === undefined || json[key] === null) continue
    fd.append(key, json[key])
  }
  return fd
}

/**
 * clone JSON
 * @param json
 */
export const clone = (json: any) => {
  return JSON.parse(JSON.stringify(json))
}

export const cloneObject = clone

/**
 * 为a-updata 提供的自定义上传方法，使用项目中axios
 * 暂时未处理onProgress的回调
 *
 * @param {Object} params - a-upload 组件内部的参数
 * {
    action: action,
    filename: _this4.name,
    data: data,
    file: transformedFile,
    headers: _this4.headers,
    withCredentials: _this4.withCredentials,
    method: props.method || 'post',
    onProgress: function onProgress(e) {
      _this4.$emit('progress', e, file);
    },
    onSuccess: function onSuccess(ret, xhr) {
      delete _this4.reqs[uid];
      _this4.$emit('success', ret, file, xhr);
    },
    onError: function onError(err, ret) {
      delete _this4.reqs[uid];
      _this4.$emit('error', err, ret, file);
    }
  }
 */
export const customUploadRequest = async (params?: any) => {
  const formData = new FormData()
  const { action, data = {}, filename, file, onError, onSuccess } = params
  data[filename] = file

  Object.keys(data).forEach((key) => {
    const value: any = data[key]
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(key + '[]', item)
      })
      return
    }

    formData.append(key, data[key])
  })
  try {
    /*    const res: any = await uploadAction(action, formData)
    res.success ? onSuccess(res, data) : onError(res, data)*/
  } catch (err) {
    onError(err, data)
  }
}

/**
 * 保存blob到本地文件
 * @param data - Blob
 * @param fileName - 文件名
 * @returns
 */
export function saveBlobFile(data: any, fileName?: any) {
  if (!data || data.size === 0) {
    message.warning('文件下载失败')
    return
  }

  fileName = fileName || (new Date() as any).toDateString()

  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(new Blob([data], { type: data.type }), fileName)
  } else {
    const url = window.URL.createObjectURL(
      new Blob([data], { type: data.type })
    )
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link) //下载完成移除元素
    window.URL.revokeObjectURL(url) //释放掉blob对象
  }
}

export function downloadFile(
  content: Blob,
  filename: string,
  type = 'application/vnd.ms-excel'
) {
  console.warn('downloadFile 工具不建议使用，请使用saveBlobFile替换')
  const blob = new Blob([content], { type })
  const bbUrl = URL.createObjectURL(blob)

  const ele = document.createElement('a') // 创建下载链接
  ele.download = filename //设置下载的名称
  ele.style.display = 'none' // 隐藏的可下载链接
  // 字符内容转变成blob地址

  ele.href = bbUrl
  // 绑定点击时间
  document.body.appendChild(ele)
  ele.click()
  // 然后移除
  document.body.removeChild(ele)
  window.URL.revokeObjectURL(bbUrl) //释放掉blob对象
}

/**
 * 随机生成数字
 *
 * 示例：生成长度为 12 的随机数：randomNumber(12)
 * 示例：生成 3~23 之间的随机数：randomNumber(3, 23)
 *
 * @param1 最小值 | 长度
 * @param2 最大值
 * @return int 生成后的数字
 */
export function randomNumber(...rest: any) {
  // 生成 最小值 到 最大值 区间的随机数
  const random = (_min: number, _max: number) => {
    return Math.floor(Math.random() * (_max - _min + 1) + _min)
  }
  if (rest.length === 1) {
    const [length] = rest
    // 生成指定长度的随机数字，首位一定不是 0
    const nums = [...Array(length).keys()].map((i) =>
      i > 0 ? random(0, 9) : random(1, 9)
    )
    return parseInt(nums.join(''))
  } else if (rest.length >= 2) {
    const [min, max] = rest
    return random(min, max)
  } else {
    return Number.NaN
  }
}
/**
 * 检查一个密码是否符合系统的规则
 *
 * No simple letter combination and at least 12 characters
 * including three of four following types: numbers, uppercase letter, lowercase letter
 * and special characters(_, -. +, =,!, @, %, *, &, ", :, ., or /)
 *
 * @param passwordStr
 */
export function passwordValidate(passwordStr: string): void {
  if (!passwordStr || passwordStr.length < 12)
    throw new Error(i18n.t('register.user.password.validate.length') as string)

  //允许的字符类型
  const allowChars: Record<string, Array<string>> = {
    number: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    uppercaseLetter: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
    lowercaseLetter: 'abcdefghijklmnopqrstuvwxyz'.split(''),
    special: [
      '_',
      '-',
      '+',
      '=',
      '!',
      '@',
      '%',
      '*',
      '&',
      '"',
      "'",
      ':',
      '.',
      '/'
    ]
  }

  //核查表
  const checkTable: Record<string, number> = {
    number: 0,
    uppercaseLetter: 0,
    lowercaseLetter: 0,
    special: 0
  }

  //检查是否有连续重复的字符，或者递增的字符，或者递减的字符
  //最多允许阈值为4
  const stateRecord = {
    maxWeakCharCount: 4,
    currentWeakCount: 1,
    state: 0, // 0：初态， 1 ascii字符相等， 2 ascii字符递增 3 ascii字符递减
    prevCode: -1,
    check: function (charCode: number) {
      if (this.state === 1 && this.prevCode === charCode) {
        this.currentWeakCount++
      } else if (this.state === 2 && this.prevCode + 1 === charCode) {
        this.currentWeakCount++
      } else if (this.state === 3 && this.prevCode - 1 === charCode) {
        this.currentWeakCount++
      } else if (this.state === 0) {
        const delta = charCode - this.prevCode
        if (delta === 0) {
          this.state = 1
        } else if (delta === 1) {
          this.state = 2
        } else if (delta === -1) {
          this.state = 3
        }

        if (this.prevCode === -1 || this.state === 0) {
          this.currentWeakCount = 1
        } else {
          this.currentWeakCount = 2
        }
      } else {
        this.state = 0
        this.currentWeakCount = 1
      }
      // console.log('common stateRecord check', charCode, this)
      this.prevCode = charCode
      return this.maxWeakCharCount >= this.currentWeakCount
    }
  }

  for (let i = 0; i < passwordStr.length; i++) {
    const curChar = passwordStr.charAt(i)
    if (allowChars.number.indexOf(curChar) !== -1) {
      checkTable.number++
    } else if (allowChars.uppercaseLetter.indexOf(curChar) !== -1) {
      checkTable.uppercaseLetter++
    } else if (allowChars.lowercaseLetter.indexOf(curChar) !== -1) {
      checkTable.lowercaseLetter++
    } else if (allowChars.special.indexOf(curChar) !== -1) {
      checkTable.special++
    } else {
      //含有其他字符，直接报错
      throw new Error(i18n.t('register.user.password.validate.error') as string)
    }

    if (!stateRecord.check(curChar.charCodeAt(0))) {
      throw new Error(i18n.t('register.characters.are.not.allowed') as string)
    }
  }

  let typeCount = 0
  for (const prop in checkTable) {
    if (checkTable[prop] > 0) {
      typeCount++
    }
  }

  if (typeCount < 3) {
    throw new Error(i18n.t('register.user.password.validate.error') as string)
  }
}
/**
 * 随机生成字符串
 * @param length 字符串的长度
 * @param chats 可选字符串区间（只会生成传入的字符串中的字符）
 * @return string 生成的字符串
 */
export function randomString(
  length = 1,
  chats = '0123456789qwertyuioplkjhgfdsazxcvbnm'
) {
  let str = ''
  for (let i = 0; i < length; i++) {
    const num = randomNumber(0, chats.length - 1)
    str += chats[num]
  }
  return str
}

export function getObjectURL(file: any) {
  let getObjectURL: any
  if ((window as any).createObjectURL !== undefined) {
    getObjectURL = (file: any) => (window as any).createObjectURL(file)
  } else if ((window as any).URL !== undefined) {
    getObjectURL = (file: any) => (window as any).URL.createObjectURL(file)
  } else if ((window as any).webkitURL !== undefined) {
    getObjectURL = (file: any) =>
      (window as any).webkitURL.createObjectURL(file)
  }
  return getObjectURL(file)
}

export function getPercent(
  num: number,
  total: number,
  unit = true,
  proportion = 100
) {
  let result = '0.00'
  if (total && num) {
    result = Number((num / total) * proportion).toFixed(2)
  }
  if (unit) {
    result += '%'
  }
  return result
}

export function checkLogin(): boolean {
  return !!(sessionStorage.getItem('login') || localStorage.getItem('login'))
}
